@font-face {
    font-family: 'SpotifyMix';
    src: url('../public/fonts/SpotifyMix-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'SpotifyReg';
    src: url('../public/fonts/SpotifyMix-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

